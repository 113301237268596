.typo-h1 {
	line-height: 1;
	font-weight: bold;
	margin: 0;
	font-size: 2.75rem;
	text-wrap: balance;

	@include media-breakpoint-up(xxl) {
		font-size: 3.875rem;
	}

	// @include media-breakpoint-down(xl) {
	// 	font-size: 3.25rem;
	// }

	// @include media-breakpoint-down(lg) {
	// 	font-size: 3rem;
	// }

	@include media-breakpoint-down(md) {
		font-size: 2.5rem;
	}

	@include media-breakpoint-down(sm) {
		font-size: 2.25rem;
	}
}

.typo-h {
	line-height: 1;
	font-weight: bold;
	margin: 0;
	font-size: 1.75rem;
	text-wrap: balance;

	@include media-breakpoint-up(xxl) {
		font-size: 2rem;
	}

	@include media-breakpoint-down(xl) {
		font-size: 1.875rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.625rem;
	}
}

.typo-h2 {
	line-height: 1;
	font-weight: bold;
	margin: 0;
	font-size: 2.875rem;
	text-wrap: auto;

	@include media-breakpoint-up(xxl) {
		font-size: 3rem;
	}

	@include media-breakpoint-down(xl) {
		font-size: 2.375rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.5rem;
	}
}

.typo-h3 {
	line-height: 1;
	font-weight: bold;
	margin: 0;
	font-size: 1.5rem;
	text-wrap: auto;

	@include media-breakpoint-down(xl) {
		font-size: 1.375rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 1.25rem;
	}
}

.typo-l {
	font-size: 0.75rem;
	line-height: 1;
	letter-spacing: 0.2em;
	margin: 0;
	font-weight: 700;
	text-transform: uppercase;
	text-wrap: balance;
}

.typo-r {
	font-size: 1rem;
	line-height: 1.5;
	font-weight: normal;
	margin: 0;

	@include media-breakpoint-up(xxl) {
		font-size: 1.125rem;
	}

	@include media-breakpoint-down(md) {
		font-size: 0.9375rem;
	}

	img {
		max-width: 100%;
	}

	h2 {
		@extend .typo-h;
		font-size: 1.5rem;
		margin-bottom: 1rem;
		text-wrap: auto;

		@include media-breakpoint-down(md) {
			font-size: 1.375rem;
		}
	}

	h3 {
		@extend .typo-h;
		font-size: 1.375rem;
		margin-bottom: 1rem;
		text-wrap: auto;

		@include media-breakpoint-down(md) {
			font-size: 1.25rem;
		}
	}

	h4 {
		font-size: 1.25rem;
		font-weight: bold;
		margin-bottom: 1rem;
		text-wrap: auto;

		@include media-breakpoint-down(md) {
			font-size: 1.125rem;
		}
	}

	h6 {
		@include media-breakpoint-up(xxl) {
			font-size: 1.25rem;
		}
	}

	p {
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	ul {
		list-style-type: none;
		padding-left: 0;
		margin-left: 1.5rem;
		margin-bottom: 1rem;

		&:last-child {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 0.75rem;
			// line-height: 1.35;
			position: relative;
			// display: flex;

			&:before {
				position: absolute;
				flex: 0 0 auto;
				left: -1.5rem;
				height: 1em;
				width: 1em;
				margin-top: 0.25em;
				margin-right: 0.5em;
				display: flex;
				border-radius: 50%;
				content: "";
				background: $white;
				border: 0.375em solid $azure;
				// top: 50%;
				// transform: translateY(-50%);

				@include media-breakpoint-up(xxl) {
					margin-top: 0.125em;
				}
			}

			&:last-child {
				margin-bottom: 0;
			}

			h6 {
				color: $azure;
			}
		}

		ul {
			margin-top: 1rem;
			li {
				&:before {
					transform: scale(0.9);
					background: $azure;
				}
			}

			ul {
				li {
					&:before {
						transform: scale(0.8);
						background: $azure;
					}
				}

				ul {
					li {
						&:before {
							transform: scale(0.75);
							background: $azure;
						}
					}
				}
			}
		}
	}

	ol {
		counter-reset: custom-counter;
		padding-left: 0;
		margin-left: 1.5rem;
		margin-bottom: 1rem;
		list-style-type: none;

		&:last-child {
			margin-bottom: 0;
		}

		li {
			margin-bottom: 0.75rem;
			// line-height: 1.35;
			position: relative;
			counter-increment: custom-counter;

			&:before {
				display: flex;
				position: absolute;
				left: -1.5rem;
				margin-right: 0.5rem;
				content: counter(custom-counter) ".";
				color: $azure;
				font-weight: bold;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		ol {
			margin-top: 1rem;
			li {
				&:before {
					// color: $dark;
				}
			}

			ol {
				li {
					&:before {
						// color: $azure;
					}
				}

				ol {
					li {
						&:before {
							// color: $dark;
						}
					}
				}
			}
		}
	}

	a {
		text-decoration: underline;
		font-weight: bold;

		&:hover {
			color: $azure;
		}
	}

	blockquote {
		color: $azure;
		border-left: 2px solid $azure;
		padding-left: 1rem;
	}

	p + h2,
	p + h3,
	ul + h2,
	ul + h3,
	ol + h2,
	ol + h3 {
		margin-top: 2rem;
	}
}

.text-color {
	color: $azure;
}

.text-color2 {
	color: $azure;
}
